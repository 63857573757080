import { AppContentSchema, Children, Children2, ContextHelpMappingFileSchema, LanguagesFileSchema, UrlParams } from "./file-schemas"
var semver = require('semver');
var semverSort = require('semver-sort');
var path = require('path');

// Mapper service can be used with another "fetchJson" to implement some unit tests.
export const mapperService = (fetchJson: (url: string) => Promise<any>) => {
    var allContent: AppContentSchema;

    const createUrl = async (params: UrlParams, content: AppContentSchema): Promise<string> => {
        allContent = content;

        var noComponentSelected = !params.component;
        if (noComponentSelected) {
            return getFallbackComponentPath();
        }

        var componentItem = content.children.find(x => x.name.replaceAll('_', '') === params.component?.toLowerCase().replaceAll('_', ''));
        const componentOrVersionsDoesNotExist = !componentItem || !componentItem.children || componentItem.children.length === 0;
        if (componentOrVersionsDoesNotExist) {
            return getFallbackComponentPath();
        }

        const noVersionSelected = !params.version;
        var versionItem: Children;
        if (noVersionSelected) {
            versionItem = getLatestComponentVersionItem(undefined, componentItem!.children!)!;
        } else {
            versionItem = getComponentVersion(componentItem!.children!, params.version!)!;
        }

        var languageSettings = await getLanguageSettings(componentItem!, versionItem!, params.language);
        if (!languageSettings) {
            return getFallbackComponentPath();
        }

        var helpUrl = combinePartsToUrl(languageSettings, componentItem!, versionItem!, params.helpId) ?? getFallbackComponentPath();

        return helpUrl;
    };

    function matchesLanguage(languageRegex: string, language: string) {
        const regex = new RegExp(languageRegex);
        return regex.test(language.toLowerCase());
    }

    async function fetchJsonTyped<DOCTYPE>(url: string): Promise<DOCTYPE> {
        const value: DOCTYPE = await fetchJson(url);
        return value;
    }

    const getComponentVersion = (versionItems: NonNullable<Children2>[], version: string): Children2 | undefined => {
        var threeDotsInVersion = (version.match(/\./g) || []).length === 3;
        if (threeDotsInVersion) {
            version = version.substr(0, version.lastIndexOf("."));
        }

        var versions = versionItems.map(x => x.name);
        var maxSatisfyingComponentVersion = semver.maxSatisfying(versions, version);

        var counter = 0;
        var versionPart = '';
        while (!maxSatisfyingComponentVersion && version.lastIndexOf('.') !== -1) {
            counter++;
            version = version.slice(0, version.lastIndexOf('.'));
            versionPart = version;
            for (let i = 0; i < counter; i++) {
                versionPart = versionPart + '.x';
            }

            maxSatisfyingComponentVersion = semver.maxSatisfying(versions, versionPart);
        };

        if (!maxSatisfyingComponentVersion) {
            var latestVersion = semver.maxSatisfying(versions, '*');
            return versionItems.find(x => x.name === latestVersion);
        }

        return versionItems.find(x => x.name === maxSatisfyingComponentVersion);
    };

    const combinePartsToUrl = async (languageSettings: LanguagesFileSchema["Languages"][0], componentItem: Children, versionItem: Children, helpId: string | null) => {
        var url: string = '';
        const versionPath = versionItem.path.replace(/\\/g, '/');
        const isDirectResolver = languageSettings.ResolverType === "direct";
        if (isDirectResolver) {
            if (!helpId) {
                var designUrlParams = { component: 'design', version: null, language: null, helpId: null };
                return createUrl(designUrlParams, allContent);
            }

            const directBaseUrl = path.join(versionPath, languageSettings.DirectTargetBaseDir, helpId + (languageSettings.DirectTargetSuffix ?? ""));
            return directBaseUrl;
        }

        const noHelpId = !helpId;
        if (!url && noHelpId) {
            var languageFallbackUrl = path.join(versionPath, languageSettings.NoMappingFallbackPage);

            url = languageFallbackUrl;
        }

        const mappingBaseDirMissing = !languageSettings.MappingTargetBaseDir;
        if (!url && mappingBaseDirMissing) {
            const latestVersionItem = getLatestComponentVersionItem(undefined, [versionItem!]);
            const latestComponentVersionUrl = path.join(versionPath, latestVersionItem?.name);

            url = latestComponentVersionUrl;
        }
        if (!url) {
            const helpIdMapFile = path.join(versionPath, languageSettings.HelpIdMappingFile);
            const helpIdMap = await fetchJsonTyped<ContextHelpMappingFileSchema[]>(helpIdMapFile);
            const helpIdItem = helpIdMap.find((item) => item.HelpID === helpId);
            if (helpIdItem) {
                var helpIdUrl = path.join(versionPath, languageSettings.MappingTargetBaseDir, helpIdItem.HelpUrl);

                url = helpIdUrl;
            }
        }

        return url;
    }

    const getLatestComponentVersionItem = (componentItem?: Children, versions?: Children2[]) => {
        if (versions) {
            const versionsSortedLatestFirst = semverSort.desc(versions.map(x => x.name));
            const latestVersion = versionsSortedLatestFirst[0];
            if (!latestVersion) {
                return null;
            }

            var latestVersionItem = versions.find(x => x.name === latestVersion);
            return latestVersionItem;
        }

        if (componentItem) {
            const versionsSortedLatestFirst = semverSort.desc(componentItem?.children?.map(x => x.name));
            const latestVersion = versionsSortedLatestFirst[0];
            if (!latestVersion) {
                return null;
            }

            return componentItem?.children?.find(x => x.name === latestVersion);
        }
    }

    async function getFallbackComponentPath(): Promise<string> {
        var contentFolderPath = process.env.REACT_APP_CONTENT_FOLDER_PATH;
        var defaultComponent = process.env.REACT_APP_DEFAULT_COMPONENT;
        var componentItem = allContent.children.find(x => x.name.replaceAll('_', '') === defaultComponent!.toLowerCase());
        var latestComponentVersionItem = getLatestComponentVersionItem(componentItem!, undefined);
        var languageSettings = await getLanguageSettings(componentItem!, latestComponentVersionItem!, null);
        const languageFallbackUrl = path.join(contentFolderPath, defaultComponent, latestComponentVersionItem?.name, languageSettings?.NoMappingFallbackPage);

        return languageFallbackUrl;
    }

    async function getLanguageSettings(component: Children, versionItem: Children2, selectedLanguage: string | null): Promise<LanguagesFileSchema["Languages"][0] | undefined> {
        const languageJsonPath = (path.join("content", component.name, versionItem.name, "languages.json")).replace(/\\/g, '/');
        const languageSettingsList = await fetchJsonTyped<LanguagesFileSchema>(languageJsonPath);

        var languageSettings;
        if (selectedLanguage) {
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, selectedLanguage));
        }

        if (!languageSettings) {
            let browserLanguage = navigator.language;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, browserLanguage));
        }

        if (!languageSettings) {
            let defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE!;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, defaultLanguage));
        }

        if (!languageSettings && languageSettingsList.FallbackLanguage) {
            let fallbackLanguage = languageSettingsList.FallbackLanguage;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, fallbackLanguage));
        }

        if (!languageSettings && languageSettingsList.Languages[0]?.NoMappingFallbackPage) {
            let fallbackLanguage = languageSettingsList.Languages[0]?.NoMappingFallbackPage;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, fallbackLanguage));
        }

        return languageSettings;
    }

    async function getContentJson(): Promise<AppContentSchema> {
        const contentPath = process.env.REACT_APP_CONTENT_FOLDER_PATH;
        var appContentPath = path.join(contentPath, "appContent.json");
        var content = await (fetchJson(appContentPath).catch(() => false));

        return content;
    }

    return {
        createUrl, getContentJson
    };
};

async function fetchJson(url: string): Promise<any> {
    var domainPath = process.env.REACT_APP_DOMAIN!;
    url = url.includes(domainPath) ? url : domainPath + url.replace(/\\/g, '/');

    const response = await fetch(url);
    if (!response.ok) {
        throw new Error("Failed to fetch '" + url + "': " + response.statusText);
    }
    const text = await response.text();
    return JSON.parse(text);
};

export const mapperServiceWithDefaultFetchJson = mapperService(fetchJson);
export { fetchJson };