import PageLayout from "./PageLayout";

function CopyrightAndTrademark() {
    return (
        <PageLayout>
            <div className="imprint, mt-5">
                <h1 className="text-center mb-2">Copyright and Trademarks</h1>
                <br />   All texts, pictures, graphics, animation, videos, sounds and any other contents of this website and their layout are protected by copyright and other protective laws. The afore-mentioned contents may not be duplicated, modified or used in other electronic or printed publications without the prior consent of TRUMPF Werkzeugmaschinen SE + Co. KG.
                <br /><br /> All patents, trademarks and works protected by copyright which are presented on this website are the intellectual property of TRUMPF SE + Co. KG unless otherwise stated. This applies in particular for TRUMPF trademarks, logos, emblems and name plates.
            </div>
        </PageLayout>
    )
}

export default CopyrightAndTrademark