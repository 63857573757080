import { useEffect, useState } from 'react';
import './styles/App.css';
import { mapperServiceWithDefaultFetchJson } from './MapperService';
import { UrlParams } from './file-schemas';

function App() {
  const [appContentExists, setAppContentExists] = useState<boolean>(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    var urlParams: UrlParams = {
      component: params.get('component'),
      version: params.get('version'),
      language: params.get('language'),
      helpId: params.get('helpid')
    };

    mapperServiceWithDefaultFetchJson.getContentJson().then(content => {
      if (!content) {
        setAppContentExists(false);
      } else {
        mapperServiceWithDefaultFetchJson.createUrl(urlParams, content)
          .then((url) => {
            var domainPath = process.env.REACT_APP_DOMAIN!;
            url = url.includes(domainPath) ? url : domainPath + url.replace(/\\/g, '/');

            setTimeout(async () => {
              const response = await fetch(url);
              if (!response.ok) {
                setAppContentExists(false);
              } else {
                window.location.href = url || ''
              }
            }, 0);
          });
      }
    });

  }, [appContentExists]);

  return (
    <div className="App">
      <header className="App-header">
        {appContentExists
          ? <p>Redirecting to help article...</p>
          : <div>
            <p>No content avaliable. </p>
            <button onClick={() => window.location.search = window.location.href.split('?')[0]}>Redirect to Homepage.</button>
          </div>
        }
      </header>
    </div>
  );
}

export default App;
