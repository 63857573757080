import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Info from './views/Info';
import CopyrightAndTrademark from './views/CopyrightAndTrademark';
import Privacy from './views/Privacy';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './styles/index.css';
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/info" element={<Info />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/copyright" element={<CopyrightAndTrademark />}/>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
