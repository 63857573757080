import PageLayout from "./PageLayout";

function Privacy() {
    return (
        <PageLayout>
            <div className="imprint, mt-5">
                <h1 className="text-center mb-2">Data privacy notice</h1>
                <br />The information in accordance with Article 13 GDPR on how your data are handled in our company can be found on our homepage <a href="https://www.trumpf.com/en_INT/meta/dataprivacynotice">https://www.trumpf.com/en_INT/meta/dataprivacynotice</a>.
            </div>
        </PageLayout>
    )
}

export default Privacy