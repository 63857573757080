import PageLayout from './PageLayout'

function Info() {
    return (
        <PageLayout>
            <div className="imprint, mt-5">
                <h1 className="text-center mb-2">Corporate Information</h1>
                <br />   <h3>Vendor of the website</h3>
                <div className="text-medium mb-5">
                    <p>
                        TRUMPF Werkzeugmaschinen SE + Co. KG
                        <br />Johann-Maus-Straße 2
                        <br />71254 Ditzingen
                        <br />Germany
                        <br />Phone +49 7156 303-0
                        <br />Fax +49 7156 303-930309
                        <br />E-mail <a href="mailto:info@trumpf.com">info@trumpf.com</a> &nbsp;
                    </p>
                    <p>
                        Management:
                        <br />Dr.-Ing. Stephan Mayer
                        <br />Dr.-Ing. Thomas Schneider
                        <br />Dipl.-Wirt. Ing. Till Küppers
                        <br />Marcella Montelatici
                    </p>
                    <p>Amtsgericht Stuttgart (local court) HRA 202772
                        <br />VAT Identification No. DE 813 103 015
                        <br />PhG TMT SE, based in Ditzingen
                        <br />Amtsgericht Stuttgart (local court) HRB 778168
                    </p>
                </div>
            </div>
        </PageLayout>
    )
}

export default Info;
